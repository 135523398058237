import CurrentLocationMarker from "@components/CurrentLocationMarker";
import GoogleMapWrapper from "@components/GoogleMapWrapper";
import NextImage from "@components/NextImage";
import Regions from "@data/localRegions.json";
import {InfoWindow, MarkerClusterer, useLoadScript} from "@react-google-maps/api";
import Head from "next/head";
import Link from "next/link";
import {useRouter} from "next/router";
import {useTranslation} from "ni18n";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {useCurrentRoute} from "src/hooks/useCurrentRoute";
import {sortLocationsByPoint} from "src/hooks/useSortLocations";
import {actions, useTypedSelector} from "src/store";
import {selectSelectedRegion} from "src/store/slices/userLocation";
import {RootStateLocation} from "src/store/types";
import {RegionSlug} from "src/store/types";
import {analytics} from "src/utils/analytics";

import googleMapStyles from "../../../public/googlemapstyles.json";
import {SpecialtyId} from "../../constants/specialtyIds";
import {useDisclosure} from "../../hooks/useDisclosure";
import {geolocateUser} from "../../hooks/useGeolocateUser";
import {I18nNamespace} from "../../i18n-namespaces";
import useMediaQuery from "../../useMediaQuery";
import {s3ImageSource} from "../../useS3ImgSrc";
import {useLatLong} from "../../utils/browser-storage/latLong";
import {
  getSkipFitBoundsOnLocationRouteChange,
  unsetSkipFitBoundsOnLocationRouteChange,
} from "../../utils/browser-storage/skipFitBoundsOnLocationsRouteChange";
import {fetchCachedSlot} from "../../utils/fetchCachedSlot";
import {locationsByRegion} from "../../utils/locationsByRegion";
import {getOpenTime} from "../../utils/timeUtils";
import {markerClusterUrl, v5Pages} from "../_common/_constants";
import {dly, usePrevious} from "../_common/Carbon";
import styles from "./LocationMapForLocations.module.scss";
import ClinicList from "./Locations/ClinicList";
import LocationDetailsCard from "./Locations/LocationDetailsCard";
import MapOptions from "./Locations/MapOptions";
import MapMarker from "./Maps/MapMarker";

export enum FilterType {
  SPECIALTY = "specialty",
}

type LocationFilter = {
  name: string;
  typ: FilterType;
  func: (l: RootStateLocation) => boolean;
  sId?: string;
};

type Props = {
  locations: RootStateLocation[];
  ignoreRegionOnLoad?: boolean;
};

// ignore region filter
const getLocationsBySpecialtyFilter = (
  locations: RootStateLocation[],
  // @ts-expect-error TS7006, TS7006: Parameter 'filters' implicitly has an 'any' type.
  filters,
): RootStateLocation[] => {
  // @ts-expect-error TS7006: Parameter 'f' implicitly has an 'any' type.
  const selectedSpecialtyName = filters.find(f => f.typ === "specialty")?.name;
  if (!selectedSpecialtyName) return locations;
  return locations.filter(l => l.specialties.vals().some(s => s.name === selectedSpecialtyName));
};

const LocationMapForLocations = ({locations, ignoreRegionOnLoad = false}: Props) => {
  const router = useRouter();
  const currentRoute = useCurrentRoute();
  const {
    query: {specialtyId, slug, city, region},
  } = router;

  const {allSpecialties, googleApiKey} = useTypedSelector(({config}) => config);
  const geoLocation = useLatLong();
  const selectedRegion = useTypedSelector(selectSelectedRegion);

  const dispatch = useDispatch();

  const isLg = useMediaQuery("lg");
  const listState = useDisclosure(true);

  // @ts-expect-error TS2532, TS2684: Object is possibly 'undefined'.,  The 'this' context of type 'LocalSpecialty[] | undefined' is not assignable to method's 'this' of type 'LocalSpecialty[]'.
  const querySpecialtyName = allSpecialties.findById(specialtyId as string)?.name;

  const i18n = useTranslation();
  const i18nDB = useTranslation(I18nNamespace.DB);
  const {isLoaded} = useLoadScript({
    // @ts-expect-error TS2322: Type 'string | undefined' is not assignable to type 'string'.
    googleMapsApiKey: googleApiKey,
  });

  const [mapRef, setMapRef] = useState<google.maps.Map>();
  const [filteredLocs, setFilteredLocs] = useState(locations);
  const [hoveredId, setHoveredId] = useState(null);
  const [clickedId, setClickedId] = useState(null);
  const [zoomedOutToCurrentLocation, setZoomedOutToCurrentLocation] = useState(false);
  const [blockZoomOutOnPanToNewRegion, setBlockZoomOutOnPanToNewRegion] = useState(false);
  const [locationsInBounds, setLocationsInBounds] = useState(
    ignoreRegionOnLoad ? locations : locationsByRegion(locations, selectedRegion),
  );
  const [soonestSlots, setSoonestSlots] = useState({});

  const filters = useMemo(
    () => ({
      specialtyId:
        (specialtyId: SpecialtyId) =>
        (l: RootStateLocation): boolean =>
          l.specialtyIds.includes(specialtyId),
    }),
    [],
  );

  const [activeFilters, setActiveFilters] = useState<LocationFilter[]>(
    // @ts-expect-error TS2345: Argument of type '{ name: string | undefined; typ: FilterType; func: (l: RootStateLocation) => boolean; }[]' is not assignable to parameter of type 'LocationFilter[] | (() => LocationFilter[])'.
    [
      specialtyId && {
        name: querySpecialtyName,
        typ: FilterType.SPECIALTY,
        func: filters.specialtyId(specialtyId as SpecialtyId),
      },
    ].compact(),
  );

  const locationsBySpecialty = getLocationsBySpecialtyFilter(locations, activeFilters);

  const prevData = {selectedRegion};
  const prev = usePrevious(prevData) || prevData;

  useEffect(() => {
    if (region) {
      dispatch(actions.setUserLocation({selectedRegion: region as RegionSlug}));
      dly(() => setBlockZoomOutOnPanToNewRegion(false), 2000);
    }
  }, [dispatch, region]);

  // run `fitBounds` only on load (zoomedOutToCurrentLocation=false) and region change
  useEffect(() => {
    if (!mapRef || !geoLocation) return; // guarantess everyting is loaded

    const isRegionChanged = prev.selectedRegion !== selectedRegion;

    if (isRegionChanged) {
      // when visitor pans to a new region, we update selectedRegion.
      // We don't need to run `fitBounds` when we pan. We run `fitBounds` only when region changed in dropdown
      // blockZoomOutOnPanToNewRegion is a hack to prevent fitbounds
      if (!blockZoomOutOnPanToNewRegion) {
        const locsInRegion = locationsByRegion(locations, selectedRegion);

        if (locsInRegion.length === 0) {
          if (!mapRef) return;
          // @ts-expect-error TS2339, TS2339: Property 'latitude' does not exist on type '{ slug: "new-york"; name: "New York"; latitude: 40.712776; longitude: -74.005974; } | { slug: "los-angeles"; name: "Los Angeles"; latitude: 34.0522; longitude: -118.2437; } | { slug: "greater-san-diego"; name: "Greater San Diego"; latitude: 33.466538; longitude: -117.362885; } | ... 21 more ... | undefined'.,  Property 'longitude' does not exist on type '{ slug: "new-york"; name: "New York"; latitude: 40.712776; longitude: -74.005974; } | { slug: "los-angeles"; name: "Los Angeles"; latitude: 34.0522; longitude: -118.2437; } | { slug: "greater-san-diego"; name: "Greater San Diego"; latitude: 33.466538; longitude: -117.362885; } | ... 21 more ... | undefined'.
          const {latitude, longitude} = Regions.find(r => r.slug === selectedRegion);
          mapRef.setCenter({lat: latitude, lng: longitude});
        } else fitBounds(locsInRegion);
      }
    } else {
      const sortedLocs = sortLocationsByPoint(locationsBySpecialty, geoLocation);

      setFilteredLocs(sortedLocs);

      // only run `fitBounds` on load (zoomedOutToCurrentLocation=false)
      // or when on mobile and list is open (letting fitbounds is better ux on mobile)
      if (!zoomedOutToCurrentLocation || listState.isOpen) {
        const skipFitBoundsOnLocationsRouteChange = getSkipFitBoundsOnLocationRouteChange();

        // add current location to fitbounds as a fake marker
        if (!skipFitBoundsOnLocationsRouteChange) {
          if (!region) {
            fitBounds([geoLocation, ...sortedLocs].slice(0, 4));
          } else {
            const locsInRegion = locationsByRegion(locations, selectedRegion);
            fitBounds(locsInRegion);
          }
        }

        if (skipFitBoundsOnLocationsRouteChange) unsetSkipFitBoundsOnLocationRouteChange();

        setZoomedOutToCurrentLocation(true);
      } else {
        // update locations in list (grid view)
        onIdle(sortedLocs);
      }
    }
  }, [
    mapRef,
    geoLocation,
    activeFilters,
    zoomedOutToCurrentLocation,
    selectedRegion,
    blockZoomOutOnPanToNewRegion,
    region,
  ]);

  // @ts-expect-error TS7006: Parameter 'id' implicitly has an 'any' type.
  const onHover = id => {
    setHoveredId(id);
    if (id !== clickedId) setClickedId(null);
  };
  // @ts-expect-error TS7006, TS7006: Parameter 'id' implicitly has an 'any' type.,  Parameter 'pan' implicitly has an 'any' type.
  const onClick = (id?, pan?) => {
    setHoveredId(null);
    setClickedId(id);
    if (pan) {
      const selectedLocation = locations.findById(id);
      // @ts-expect-error TS2532, TS2532: Object is possibly 'undefined'.,  Object is possibly 'undefined'.
      mapRef.panTo(new google.maps.LatLng(selectedLocation.x, selectedLocation.y));
    }
    return !isLg && dly(() => window.scrollTo(0, 0), 2000);
  };

  // @ts-expect-error TS7006: Parameter 'locs' implicitly has an 'any' type.
  const fitBounds = locs => {
    if (!mapRef) return;
    const bounds = new window.google.maps.LatLngBounds();

    locs.forEach(({x, y}: any) => {
      if (typeof x === "number" && typeof y === "number") {
        bounds.extend({
          lat: x,
          lng: y,
        });
      }
    });

    mapRef.fitBounds(bounds);

    dly(() => {
      if (locs.length === 1) {
        mapRef.setZoom(12);
      } else {
        // @ts-expect-error TS2345: Argument of type 'number | undefined' is not assignable to parameter of type 'number'.
        mapRef.setZoom(mapRef.getZoom());
      }
    }, 100);
  };

  const getCenterOfMapCoord = () => {
    const latLongLiteral = mapRef?.getCenter()?.toJSON();
    return latLongLiteral ? {x: latLongLiteral.lat, y: latLongLiteral.lng} : null;
  };

  const onIdle = (onIdleLocs = filteredLocs) => {
    if (!mapRef) return;
    const bounds = mapRef.getBounds();
    if (!bounds) return;
    const locs = onIdleLocs.filter(l => bounds.contains(new google.maps.LatLng(l.x, l.y)));

    const sortedLocs = geoLocation ? sortLocationsByPoint(locs, geoLocation) : locs;
    setLocationsInBounds(sortedLocs);
    fetchSlots(sortedLocs);
  };

  // @ts-expect-error TS7006: Parameter 'sortedLocs' implicitly has an 'any' type.
  const fetchSlots = sortedLocs => {
    if (sortedLocs.length > 0) {
      sortedLocs
        .slice(0, 10) // limit number of location, otherwise this can make hundred of calls when patient zooms out in map
        // @ts-expect-error TS7031, TS7031: Binding element 'id' implicitly has an 'any' type.,  Binding element 'specialties' implicitly has an 'any' type.
        .map(({id, specialties}) =>
          specialties
            .getValues()
            // @ts-expect-error TS7006: Parameter 's' implicitly has an 'any' type.
            .filter(s => !s.isVirtual)
            // @ts-expect-error TS7031: Binding element 'sId' implicitly has an 'any' type.
            .map(({id: sId}) =>
              fetchCachedSlot({locationId: id, specialtyId: sId}).then(slot => [sId, slot?.time]),
            )
            .sequence()
            // @ts-expect-error TS7006: Parameter 'pars' implicitly has an 'any' type.
            .then(pars => {
              setSoonestSlots(prevSlots => ({...prevSlots, ...{[id]: pars.toObject()}}));
            }),
        );
    }
  };

  const locate = async () => {
    if (!mapRef) return;

    const geoLocation = await geolocateUser(true);
    const pos = {
      lat: geoLocation.x,
      lng: geoLocation.y,
    };

    mapRef.setCenter(pos);
    mapRef.setZoom(14);

    analytics.post({
      category: analytics.category.LOCATION_DISCOVERY,
      label: analytics.label.ADJUST_LOCATION,
      action: analytics.action.CLICKED,
      extraData: {
        source: currentRoute,
        value: "Locate Me",
        isMapPopup: false,
      },
    });

    // update dist value of locations after locating
    if (!slug && !city) {
      const sortedLocations = sortLocationsByPoint(locations, geoLocation);
      dispatch(actions.setConfig({locations: sortedLocations}));
    } else {
      router.push({pathname: v5Pages.locations}, undefined, {shallow: true});
    }
  };

  // @ts-expect-error TS2345: Argument of type 'null' is not assignable to parameter of type 'string'.
  const location = locations.findById(clickedId);

  const expandBoundsToShowLocations = () => {
    const point = getCenterOfMapCoord();
    const sortedLocs = point ? sortLocationsByPoint(filteredLocs, point) : filteredLocs;
    const {x, y} = sortedLocs[0] || {};
    const bounds = new window.google.maps.LatLngBounds();
    if (point) {
      bounds.extend({lat: point.x, lng: point.y});
    }
    bounds.extend({lat: x, lng: y});
    mapRef?.fitBounds(bounds);
  };

  const renderInfobox = () => {
    // Move all this into LocationDetailsCard at end of experiment from here...

    const {timeString, timeBlock, isOpenNow, daysFromToday, isBeforeOpeningToday} = getOpenTime(
      i18nDB,
      // @ts-expect-error TS2345: Argument of type 'RootStateLocation | undefined' is not assignable to parameter of type 'RootStateLocation'.
      location,
    );

    // ...to here.
    return (
      <div className="br3 p3 bg-white">
        <LocationDetailsCard
          // @ts-expect-error TS2322: Type 'RootStateLocation | undefined' is not assignable to type 'RootStateLocation'.
          location={location}
          isMapPopup
          timeString={timeString}
          timeBlock={timeBlock}
          isOpenNow={isOpenNow}
          isBeforeOpeningToday={isBeforeOpeningToday}
          daysFromToday={daysFromToday}
          soonestSlots={soonestSlots}
        />
      </div>
    );
  };

  const renderMap = () => {
    if (!isLoaded || !geoLocation) {
      return null;
    }

    return (
      <GoogleMapWrapper
        onZoomChanged={() => dly(() => !isLg && window.scrollTo(0, 0), 1000)}
        clickableIcons={false}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          styles: googleMapStyles,
        }}
        onClick={() => setClickedId(null)}
        onLoad={map => setMapRef(map)}
        onIdle={onIdle}
      >
        <MarkerClusterer
          options={{
            imagePath: markerClusterUrl,
            enableRetinaIcons: true,
            averageCenter: true,
            gridSize: 40,
            zoomOnClick: false,
            maxZoom: 9,
            minimumClusterSize: 3,
          }}
          onClick={cluster => {
            // @ts-expect-error TS2345: Argument of type 'LatLng | undefined' is not assignable to parameter of type 'LatLng | LatLngLiteral'.
            mapRef.setCenter(cluster.getCenter());
            // @ts-expect-error TS2532: Object is possibly 'undefined'.
            mapRef.setZoom(mapRef.getZoom() + 1);
            dly(() => !isLg && window.scrollTo(0, 0), 2000);
          }}
        >
          {clusterer =>
            // @ts-expect-error TS7006: Parameter 'l' implicitly has an 'any' type.
            locationsBySpecialty.map(l => {
              const clickedOrHovered = l.id === clickedId || l.id === hoveredId;
              const handleClick = () => {
                if (clickedOrHovered) {
                  onClick(null);
                  dly(() => !isLg && window.scrollTo(0, 0), 2000);
                } else {
                  onClick(l.id, true);
                }
              };

              return (
                <MapMarker
                  key={l.id}
                  title={l.name}
                  lat={l.x}
                  lng={l.y}
                  // @ts-expect-error TS2322: Type 'Clusterer | undefined' is not assignable to type 'Clusterer'.
                  clusterer={clickedOrHovered ? undefined : clusterer}
                  clickedOrHovered={clickedOrHovered}
                  onClick={handleClick}
                />
              );
            })
          }
        </MarkerClusterer>
        {geoLocation && <CurrentLocationMarker key="current-location" {...{geoLocation}} />}
        {clickedId && (
          <InfoWindow
            position={{
              // @ts-expect-error TS2532: Object is possibly 'undefined'.
              lat: location.x,
              // @ts-expect-error TS2532: Object is possibly 'undefined'.
              lng: location.y,
            }}
            onCloseClick={() => {
              setClickedId(null);
              setHoveredId(null);
            }}
          >
            {renderInfobox()}
          </InfoWindow>
        )}
      </GoogleMapWrapper>
    );
  };

  const isFilterActive = (filter: LocationFilter) =>
    activeFilters.map(f => f.name).includes(filter.name);

  const getToggleFilterHandler = (filter: LocationFilter) => () => {
    const {typ, func, name, sId} = filter;
    const filterIsActive = isFilterActive(filter);
    if (filterIsActive) {
      router.replace({query: {}}, undefined, {shallow: true});
      setActiveFilters(activeFilters.filter(f => f.name !== name));
    } else {
      router.push({query: {specialtyId: sId}}, undefined, {shallow: true});
      setActiveFilters([{name, typ, func}]);
    }
  };

  const renderNoLocationFound = () => (
    <div className={`${styles.locEmpty} p4`}>
      <strong>{i18n.t("No locations found")}</strong>
      <div className="mt4">
        <button
          className="brdn p0 m0 bg-transparent font-c cp brand hover-darkerMint"
          onClick={expandBoundsToShowLocations}
        >
          {i18n.t("Go to closest location")}
          <span aria-hidden> &rarr;</span>
        </button>
      </div>
    </div>
  );

  const locationsBySpecialtyInRegion = useMemo(
    () => locationsByRegion(locationsBySpecialty, selectedRegion),
    [locationsBySpecialty, selectedRegion],
  );

  return (
    <div
      id="map"
      className={styles.map}
      style={{
        "--filterActive": "brightness(80%)",
      }}
    >
      <h1 className="visually-hidden">{i18n.t("Our Locations")}</h1>

      <Head>
        <style
          // hide stupid popup shows on click random places
          dangerouslySetInnerHTML={{
            __html: !clickedId && ".gm-style-iw-a{display: none !important;}",
          }}
        />
      </Head>

      <div className={styles.left} id="left">
        <div className={styles.leftTopWrapper}>
          <MapOptions
            getToggleFilterHandler={getToggleFilterHandler}
            filters={filters}
            isFilterActive={isFilterActive}
            locate={locate}
          />
        </div>
        {!isLg && (
          <div className={styles.leftMain} id="leftMain">
            <div className={`${styles.locs} bg-white`}>
              <h2 className="visually-hidden" aria-live="polite">
                {i18n.t("Our Locations in Your Area ({{text}} results)", {
                  text: locationsInBounds.length,
                })}
              </h2>
              {!locationsInBounds.length ? (
                renderNoLocationFound()
              ) : (
                <ul>
                  {locationsInBounds
                    .sortBy(l => l.id === clickedId || l.dist)
                    .map((l, i) => {
                      // Move this all into LocationDetailsCard after experiment from here...
                      // @ts-expect-error TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
                      const firstSlot = soonestSlots[l.id]?.vals().min();

                      const {
                        timeString,
                        timeBlock,
                        isOpenNow,
                        daysFromToday,
                        isBeforeOpeningToday,
                      } = getOpenTime(i18nDB, l);

                      const eventDataList = {
                        category: analytics.category.LOCATION_DISCOVERY,
                        action: analytics.action.CLICKED,
                        label: analytics.label.SELECT_CLINIC,
                        extraData: {
                          locationId: l.id,
                          milesAway: l.dist,
                          openNow: isOpenNow,
                          nextAvailable: firstSlot,
                          isMapPopup: false,
                          source: "index",
                        },
                      };

                      return (
                        <li key={l.id} className="brdb brd-gray100">
                          <Link
                            href={{
                              pathname: v5Pages.clinicDetails,
                              query: {slug: l.slug},
                            }}
                            className={`${styles.loc} pos-r df focus-bsDarkBlue-hug hover-bg-gray100 gray800 hover-gray800 m2 p2 br3`}
                            onMouseEnter={() => onHover(l.id)}
                            onMouseLeave={() => onHover(null)}
                            onFocus={() => onHover(l.id)}
                            onClick={() => {
                              analytics.post(eventDataList);
                            }}
                            data-cy="location-rows"
                          >
                            <div className="pos-r fx2">
                              <NextImage
                                className="br2"
                                priority={i === 0 || i === 1}
                                src={s3ImageSource(l.images?.[0]?.imageId || "", "jpg", 2)}
                                layout="fill"
                                alt=""
                              />
                            </div>
                            <div className="fx2 minh54 ph4 pt1">
                              <LocationDetailsCard
                                location={l}
                                timeString={timeString}
                                timeBlock={timeBlock}
                                isOpenNow={isOpenNow}
                                isBeforeOpeningToday={isBeforeOpeningToday}
                                daysFromToday={daysFromToday}
                                isMobileList
                                disableButton
                                soonestSlots={soonestSlots}
                              />
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
      {!locationsInBounds.length && (
        <div className={styles.locEmptyMobileWrapper}>{renderNoLocationFound()}</div>
      )}
      <div className={styles.right}>
        {isLg && (
          <button
            className="focus-bsDarkBlue3 br5 brd1nc brd-gray100 font-isb fs12 p2 df aic mt2 ml4 zIndex2 lh2 pos-a-f gray800 bg-white bs1"
            onClick={() => {
              listState.toggle();
              analytics.post({
                category: analytics.category.LOCATION_DISCOVERY,
                action: analytics.action.CLICKED,
                label: analytics.label.LOCATION_INDEX_VIEW_SWITCH,
                value: listState.isOpen ? i18n.t("Show Map") : i18n.t("Show List"),
              });
            }}
          >
            <span className={`mr1 cIcon-${listState.isOpen ? "map-pin" : "list"}`} aria-hidden />
            <span>{listState.isOpen ? i18n.t("Show Map") : i18n.t("Show List")}</span>
          </button>
        )}
        <div className={`${styles.rightInner} relative`}>
          {isLg && listState.isOpen && (
            <>
              <h2 className="visually-hidden" aria-live="polite">
                {i18n.t("Our Locations in Your Area ({{text}} results)", {
                  text: locationsBySpecialtyInRegion.length,
                })}
              </h2>
              <ClinicList locations={locationsBySpecialtyInRegion} soonestSlots={soonestSlots} />
            </>
          )}
          {renderMap()}
        </div>
      </div>
    </div>
  );
};

export default LocationMapForLocations;
